import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { get } from 'lodash'

// atoms
import { Tooltip } from 'react-tippy'
import Button from '../../atoms/Button'

// components
import Tabs from '../../components/Prilezitosti/DetailPrilezitosti/Tabs'
import VseobecneTabContent from '../../components/Prilezitosti/DetailPrilezitosti/VseobecneTabContent'
import HistoriaTabContent from '../../components/Prilezitosti/DetailPrilezitosti/HistoriaTabContent'
import DokumentyTabContent from '../../components/Prilezitosti/DetailPrilezitosti/DokumentyTabContent'
import ProduktTabContent from '../../components/Prilezitosti/DetailPrilezitosti/ProduktTabContent'
import ElementLoading from '../../components/ElementLoading'

// actions
import * as PrilezitostiAction from '../../actions/PrilezitostiActions'

// utils
import { putReq } from '../../utils/request'
import { history } from '../../utils/history'
import { PRILEZITOSTI, PRILEZITOSTI_UPRAVIT, setRouteParams } from '../../utils/routes'
import Permissions, { PERMISSIONS, withPermissions } from '../../utils/permissionsHoc'
import { canEditLead, canSeizeLead } from '../../utils/prilezitosti'
import ElementEmptyContent from '../../components/ElementEmptyContent'

const TAB_KEYS = {
	VSEOBECNE: 'VSEOBECNE',
	PRODUKT: 'PRODUKT',
	DOKUMENTY: 'DOKUMENTY',
	HISTORIA: 'HISTORIA'
}

const DEFAULT_TAB = TAB_KEYS.VSEOBECNE

function PrilezitostiDetailPage(props) {
	const { computedMatch } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB)
	const [isLoading, setIsLoading] = useState(false)

	const prilezitost = useSelector((state) => state?.prilezitosti?.prilezitost)
	const auth = useSelector((state) => state.auth)
	const interakcia = useSelector((state) => state?.interakcie?.detail?.data)

	const prilezitostId = computedMatch?.params?.prilezitostId
	const opCislo = computedMatch?.params?.cisloOP

	useEffect(() => {
		dispatch(PrilezitostiAction.loadDetailPrilezitosti(prilezitostId))
	}, [prilezitostId])

	const assign = async () => {
		try {
			setIsLoading(true)
			const updateBody = { ...prilezitost.data, riesitel: auth.user.email }
			await putReq(`/api/v0/prilezitosti/${prilezitostId}`, null, updateBody)
			dispatch(PrilezitostiAction.loadDetailPrilezitosti(prilezitostId))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	}

	const items = [
		{
			key: TAB_KEYS.VSEOBECNE,
			label: t('components:Prilezitosti.Všeobecné'),
			children: <VseobecneTabContent prilezitost={prilezitost.data} />
		},
		{ key: TAB_KEYS.PRODUKT, label: 'Produkt', children: <ProduktTabContent prilezitost={prilezitost.data} /> },
		{
			key: TAB_KEYS.DOKUMENTY,
			label: t('components:Prilezitosti.Dokumenty'),
			children: <DokumentyTabContent dokumenty={get(prilezitost, 'data.dokumenty', [])} />
		},
		{
			key: TAB_KEYS.HISTORIA,
			label: t('components:Prilezitosti.História'),
			children: <HistoriaTabContent logs={get(prilezitost, 'data.log', [])} />
		}
	]

	if (prilezitost.isLoading || isLoading) {
		return <ElementLoading />
	}

	if (prilezitost.isFailure) {
		return (
			<div>
				<div className='content-header'>
					<div className={'flex items-center gap-20'}>
						<button
							onClick={() => history.push(setRouteParams(PRILEZITOSTI, get(prilezitost, 'data.op.cislo')))}
							type='button'
							className='button pull-left'
							data-type='back-button'
							data-color='blue'
						>
							{t('translation:Common.Späť')}
						</button>
						<span>{`${t('translation:Common.Detail príležitosti')}`}</span>
					</div>
				</div>
				<div className={'relative'} style={{ top: '15px', minHeight: '318px' }}>
					<ElementEmptyContent text={t('translation:Prilezitosti.Žiadne dáta')} />
				</div>
			</div>
		)
	}

	return (
		<>
			<div className='content-header flex justify-between'>
				<div className={'flex items-center gap-20'}>
					<button
						onClick={() => history.push(setRouteParams(PRILEZITOSTI, get(prilezitost, 'data.op.cislo')))}
						type='button'
						className='button pull-left'
						data-type='back-button'
						data-color='blue'
					>
						{t('translation:Common.Späť')}
					</button>
					<span>{`${t('translation:Common.Detail príležitosti')} (${get(prilezitost, 'data.id', '-')})`}</span>
				</div>
				<div className={'flex items-center gap-15'}>
					{/* TODO ma tu byt este cislo podla figmy, ake je to cislo? */}
					{/* <div>1234567890</div> */}
					{/* TODO add action */}
					<Permissions
						allowed={[PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED]}
						render={(hasPerm, actions) => (
							<Button
								type={'secondary'}
								label={t('translation:Prilezitosti.Upraviť')}
								onClick={() => {
									if (hasPerm && canEditLead(interakcia, auth, prilezitost.data)) {
										history.replace(setRouteParams(PRILEZITOSTI_UPRAVIT, opCislo, prilezitostId))
									} else {
										actions.openForbiddenModal()
									}
								}}
							/>
						)}
					/>
				</div>
			</div>
			<div className={'p-15'}>
				<Tabs onChange={(key) => setSelectedTab(key)} items={items} defaultActiveKey={DEFAULT_TAB} />
				{selectedTab === TAB_KEYS.VSEOBECNE && (
					<div className={'flex items-center gap-10 mt-15'}>
						{/* TODO maju byt vsetky btns podla SRS? */}
						<Permissions
							allowed={[PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED]}
							render={(hasPerm, actions) => (
								<Button
									type={'primary'}
									label={t('translation:Prilezitosti.Prevziať príležitosť')}
									onClick={() => {
										if (
											hasPerm &&
											canSeizeLead(
												`${interakcia?.riesitelIdentita?.oddelenie?.kod}-${interakcia?.riesitelIdentita?.oddelenie?.nazov}`,
												prilezitost.data,
												auth
											)
										) {
											assign()
										} else {
											actions.openForbiddenModal()
										}
									}}
								/>
							)}
						/>
						<Button type={'secondary'} disabled label={t('translation:Prilezitosti.Vrátiť príležitosť nadriadenému')} />
						{/* TODO Priradiť... Má byť len jedno a na pozadí sa cez oprávnenia určí, čo sa spustí. */}
						<Button type={'secondary'} disabled label={t('translation:Prilezitosti.Priradiť príležitosť')} />
						{/* <Button type={'secondary'} disabled label={t('translation:Prilezitosti.Priradiť príležitosťaj mimo môjho oddelenia')} /> */}
					</div>
				)}
			</div>
		</>
	)
}

PrilezitostiDetailPage.protoType = {
	computedMatch: PropTypes.shape({
		params: PropTypes.shape({
			cisloOP: PropTypes.any.isRequired,
			prilezitostId: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}

export default compose(withPermissions([PERMISSIONS.VIEW_LEADS]))(PrilezitostiDetailPage)
